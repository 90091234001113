<!-- 编辑团购 -->
<template>
  <div class="container">
    <div style="cursor: pointer;" @click="$router.go(-1)"><i class="arrow_back el-icon-back"></i>返回</div>
    <TitleBarBaseVue />
    <el-row>
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm">
        <el-form-item label="团购类型" prop="name">
          <el-radio-group v-model="ruleForm.radio">
            <el-radio :label="3">备选项</el-radio>
            <el-radio :label="6">备选项</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="是否分利" prop="name">
          <el-switch v-model="ruleForm.delivery"></el-switch>
        </el-form-item>
        <el-form-item label="拼团人数" prop="name">
          <el-col :span="6">
            <el-input v-model="ruleForm.name"></el-input>
          </el-col>
          <span class="text-danger margin-left-sm">注：最少拼团人数2人！</span>
        </el-form-item>
        <el-form-item label="每人限购数量" prop="name">
          <el-col :span="6">
            <el-input v-model="ruleForm.name"></el-input>
          </el-col>
          <span class="text-danger margin-left-sm">注：每个人最多可以团购这件商品的数量！</span>
        </el-form-item>
        <el-form-item label="拼团时间" prop="date1">
          <el-col :span="6">
            <el-date-picker
              style="width:100%"
              v-model="date"
              type="daterange"
              align="right"
              value-format="yyyy-MM-dd"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              @change="changeDate"
            >
            </el-date-picker>
          </el-col>
          <span class="text-danger margin-left-sm">注：团购时间范围，如果团购时间结束，拼团人数未满则为拼团失败！</span>
        </el-form-item>
        <el-form-item prop="name">
          商品卡片
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')">立即创建</el-button>
          <el-button @click="resetForm('ruleForm')">重置</el-button>
        </el-form-item>
      </el-form>
    </el-row>
  </div>
</template>

<script>
import TitleBarBaseVue from '@/components/TitleBar/TitleBarBase.vue'

export default {
  name: '',
  components: {
    TitleBarBaseVue
  },
  data() {
    return {
      ruleForm: {
        name: '',
        region: '',
        date1: '',
        date2: '',
        delivery: false,
        type: [],
        resource: '',
        desc: '',
        radio: ''
      },
      rules: {
        name: [
          { required: true, message: '请输入活动名称', trigger: 'blur' },
          { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        region: [{ required: true, message: '请选择活动区域', trigger: 'change' }],
        date1: [{ type: 'date', required: true, message: '请选择日期', trigger: 'change' }],
        date2: [{ type: 'date', required: true, message: '请选择时间', trigger: 'change' }],
        type: [{ type: 'array', required: true, message: '请至少选择一个活动性质', trigger: 'change' }],
        resource: [{ required: true, message: '请选择活动资源', trigger: 'change' }],
        desc: [{ required: true, message: '请填写活动形式', trigger: 'blur' }]
      },
      date: ''
    }
  },
  mounted() {},
  methods: {
    changeDate(v) {
      if (v) {
        this.ruleForm.startTime = v[0]
        this.ruleForm.endTime = v[1]
      } else {
        this.ruleForm.startTime = ''
        this.ruleForm.endTime = ''
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          alert('submit!')
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    }
  }
}
</script>
<style lang="less" scoped>
/* @import url(); 引入css类 */
.text-danger {
  color: red;
}
.inline-block {
  display: inline-block;
}
</style>
